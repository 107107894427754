@import "../common/style/ui-const.scss";
@import "../common/style/ui-anim.scss";

.menu-bg,
.main-menu {
  min-height: 100vh;
}

.bgm-wrapper {
  visibility: hidden;
}

.title video {
  z-index: -1;
}

.logo-wrapper {
  position: fixed;
  top: 75%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
}

#titleLogo {
  display: block;
}

#clickToStart {
  position: fixed;
  left: 33%;
  padding-top: 25px;
}

// Pixel 2 and other similar sized Android phones
@media only screen and (max-device-width: 823px) and (max-device-height: 411px) and (orientation: landscape) {
  .title video {
    min-height: calc(100vh + 1px);
  }

  .logo-wrapper {
    top: 65%;
  }
}

// iPhone X
@media only screen and (max-device-width: 812px) and (max-device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .title video {
    min-height: calc(100vh + 1px);
  }

  .logo-wrapper {
    top: 65%;
  }
}
