@import "../../style/ui-anim.scss";

.single-note-anchor {
  position: absolute;
}

.single-note {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.single-animation {
  pointer-events: none;
}