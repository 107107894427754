@import "../../common/style/ui-const.scss";
@import "../../common/style/ui-anim.scss";

.column {
  display: flex;
  padding-right: 2vw;
  padding-left: 2vw;
  z-index: 2;
}

.column:last-of-type {
  padding-right: 2vw;
}

.row {
  padding-top: 10vh !important;
  flex-wrap: initial !important;
  height: 100vh;
  margin-left: 5% !important;
}

.portrait {
  min-height: 70vh;
  min-width: 10vw;
  max-width: 10vw;
}

.portrait-container {
  position: relative;
  text-align: center;
}

.portrait-gradient {
  position: absolute;
  height: 10%;
  width: 100%;
  background: linear-gradient(180deg, black 0%, rgba(255, 255, 255, 0) 100%);
  transition: $boxTransitionFast;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 25px;
}

.character-name {
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  letter-spacing: 0.05em;
  font-size: 32px;
  color: $base;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 210px;
  text-shadow: 0px 5px 2px rgba(241, 233, 200, 0.25);
}

.character-exp {
  position: absolute;
  top: 5%;
  height: 1%;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 25px;
}

.character-level {
  position: absolute;
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  font-size: 26px;
  color: $base;
  top: 6%;
  height: 1%;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
}

.character-level img {
  width: 25px;
  height: 15px;
  box-shadow: 1px 2px 2px 0px rgba(241, 233, 200, 0.75);
}

.character-level span {
  text-shadow: 0px 4px 2px rgba(241, 233, 200, 0.75);
  top: 1px;
  position: relative;
}

.portrait-narrow {
  width: 15vw;
  height: auto;
  transition: $boxTransitionFast;
  border-radius: 25px;
}

.portrait-narrow:hover {
  filter: contrast(150%) saturate(125%);
}

.portrait-container-full {
  display: flex;
  position: relative;
  width: 65vw;
  right: 4vh;
  text-align: center;
}

.character-container {
  position: relative;
  width: 75%;
}

.character-logo {
  position: absolute;
  width: auto;
  min-height: 50vh;
  left: 0;
  opacity: 0.5;
}

.portrait-full {
  width: auto;
  height: 90vh;
  filter: contrast(150%) saturate(125%);
}

.playBtn {
  position: absolute;
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5em;
  font-size: 26px;
  color: $base;
  top: 45%;
  width: 15%;
  height: 5%;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

.play-base {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border: 1px solid $base;
  transition: $fastTransition;
  &:before {
    content: "";
    width: 2px;
    height: 2px;
    left: -1px;
    top: -1px;
    background: #0f1923;
    position: absolute;
    transition: $normalTransition;
  }
}

.lock-base {
  border: 1px solid $lockBG;
}

.play-bg {
  position: absolute;
  background: $base;
  height: 100%;
  transition: $slowTransition;
  opacity: 0;
  width: 500px;
  left: -100px;
}

.lock-bg {
  background: repeating-linear-gradient(45deg, $lockBG, $lockBG 10px, $darkLockBG 10px, $darkLockBG 20px);
}

.play-text {
  position: absolute;
  left: 53%;
  top: 48%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.locked {
  font-size: 1.1rem;
  color: $lockText;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;

  &:hover {
    color: $white;
  }
}

.playBtn:hover {
  color: $white;
  cursor: pointer;

  & .play-bg {
    opacity: 0.5;
  }
  & .play-text:after {
    background: $white;
  }
}

.campaign-details {
  position: relative;
  padding-top: 5%;
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  font-size: 29px;
  color: $base;
  flex: 1;
  text-shadow: 0px 4px 2px rgba(241, 233, 200, 0.75);
}

.campaign-details .name {
  border-top: 1px solid #f5eccb86;
  border-bottom: 1px solid #f5eccb86;
  padding-top: 5%;
  padding-bottom: 5%;
}

.campaign-details .character-level {
  display: flex;
  font-size: 40px;
  letter-spacing: 0;
  position: relative;
  border-bottom: 1px solid #f5eccb86;
  padding: 0;
  width: 100%;
  transform: none;
  top: 0;
  left: 0;
  height: auto;
}

.exp-counter {
  flex: 1;

  .exp {
    position: absolute;
    width: 125px;
    font-size: 29px;
    transform: translateX(-50%);
    top: 0;
    left: 80%;

    img {
      width: auto;
      height: auto;
      box-shadow: none;
    }
  }

  .character-exp {
    position: absolute;
    height: 15%;
    width: 120px;
    border-radius: 0;
    transform: translateX(-50%);
    top: 66%;
    left: 80%;
  }
}

.score-panel {
  width: 100%;
  margin-top: 10%;
  margin-bottom: 10%;
}

.score-title {
  display: block;
  text-align: left;
  font-size: 23px;
}

.total {
  display: block;
  text-align: right;
  padding-right: 10px;
  background-color: rgba(0, 0, 0, 0.25);
}

.cleared-panel {
  width: 100%;
}

.difficulty {
  display: block;
  min-height: 45px;
  background-color: rgba(0, 0, 0, 0.25);
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5%;
}

.title {
  float: left;
}

.total-songs {
  float: right;
}

// iPhone 5/6/7/8
@media only screen and (max-device-width: 736px) and (max-device-height: 414px) and (orientation: landscape) {
  .portrait-narrow {
    border-radius: 8px;
  }

  .character-name {
    font-size: 15px;
  }

  .character-exp {
    top: 8%;
  }

  .character-level {
    top: 8%;
    font-size: 20px;
  }

  .character-level img {
    width: 20px;
    height: 12px;
  }

  .playBtn {
    font-size: 16px;
    width: 20%;
    height: 8%;
  }

  .campaign-details {
    font-size: 13px;

    .name {
      font-size: 17px;
    }

    .character-level {
      font-size: 21px;
    }
  }

  .exp-counter {
    .exp {
      width: 55px;
      font-size: 12px;
    }

    .character-exp {
      width: 40px;
    }
  }

  .score-panel {
    margin-top: 0;
    margin-bottom: 0;
  }

  .score-title {
    font-size: 12px;
  }

  .difficulty {
    min-height: 20px;
  }
}

// iPhone X
@media only screen and (max-device-width: 812px) and (max-device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .row {
    padding-top: 5vh !important;
  }

  .portrait-narrow {
    border-radius: 8px;
  }

  .character-name {
    font-size: 18px;
  }

  .character-exp {
    top: 8%;
  }

  .character-level {
    top: 8%;
    font-size: 20px;
  }

  .character-level img {
    width: 20px;
    height: 12px;
  }

  .playBtn {
    font-size: 16px;
    width: 20%;
    height: 8%;
  }

  .campaign-details {
    font-size: 13px;

    .name {
      font-size: 17px;
    }

    .character-level {
      font-size: 21px;
    }
  }

  .exp-counter {
    .exp {
      width: 55px;
      font-size: 12px;
    }

    .character-exp {
      width: 50px;
    }
  }

  .score-panel {
    margin-top: 0;
    margin-bottom: 0;
  }

  .score-title {
    font-size: 16px;
  }

  .difficulty {
    min-height: 20px;
  }
}

// Pixel 2 and other similar sized Android phones
@media only screen and (max-device-width: 823px) and (max-device-height: 411px) and (orientation: landscape) {
  .portrait-narrow {
    border-radius: 8px;
  }

  .character-name {
    font-size: 16px;
  }

  .character-exp {
    top: 8%;
  }

  .character-level {
    top: 8%;
    font-size: 20px;
  }

  .character-level img {
    width: 20px;
    height: 12px;
  }

  .playBtn {
    font-size: 14px;
    width: 20%;
    height: 8%;
  }

  .campaign-details {
    font-size: 13px;

    .name {
      font-size: 17px;
    }

    .character-level {
      font-size: 21px;
    }
  }

  .exp-counter {
    .exp {
      width: 55px;
      font-size: 12px;
    }

    .character-exp {
      width: 40px;
    }
  }
}

// Tablets
@media (min-width: 1024px) and (max-device-height: 1024px) {
  .portrait-narrow {
    width: 22vw;
  }

  .portrait-container-full {
    width: 80vw;
  }

  .character-name {
    font-size: 26px;
  }

  .character-exp {
    top: 7%;
  }

  .character-level {
    top: 8%;
  }

  .playBtn {
    font-size: 22px;
    width: 20%;
  }

  .campaign-details {
    font-size: 18px;

    .name {
      font-size: 24px;
    }

    .character-level {
      font-size: 22px;
    }
  }

  .exp-counter {
    .exp {
      width: 60px;
      font-size: 14px;
    }

    .character-exp {
      width: 60px;
    }
  }

  .score-title {
    font-size: 20px;
  }

  .difficulty {
    min-height: 30px;
  }
}

@media (min-width: 1366px) and (max-device-height: 1024px) {
  .character-exp {
    top: 6%;
  }

  .character-level img {
    width: 30px;
    height: 20px;
  }

  .playBtn {
    font-size: 26px;
    width: 20%;
  }

  .campaign-details {
    font-size: 24px;

    .character-level {
      font-size: 26px;
    }
  }

  .exp-counter {
    .exp {
      width: 80px;
      font-size: 18px;
    }

    .character-exp {
      width: 80px;
    }
  }

  .difficulty {
    min-height: 37px;
  }
}

// 720p
@media (min-width: 1280px) and (max-device-height: 720px) {
  .portrait-narrow {
    width: 16vw;
  }

  .playBtn {
    width: 20%;
    height: 7%;
  }

  .campaign-details {
    font-size: 23px;

    .character-level {
      font-size: 28px;
    }
  }

  .exp-counter {
    .exp {
      width: 125px;
      font-size: 19px;
    }

    .character-exp {
      width: 80px;
    }
  }

  .score-title {
    font-size: 19px;
  }

  .difficulty {
    min-height: 35px;
  }
}

// 1080p
@media (min-width: 1920px) {
  .portrait-narrow {
    width: 17vw;
  }

  .character-exp {
    top: 6%;
  }

  .character-level {
    top: 8%;
  }

  .character-level img {
    width: 33px;
    height: 20px;
  }
}

// 1440p
@media (min-width: 2560px) {
  .character-exp {
    top: 5%;
  }

  .character-level {
    top: 7%;
    font-size: 29px;
  }

  .character-level img {
    width: 36px;
    height: 23px;
  }

  .playBtn {
    font-size: 30px;
  }

  .campaign-details {
    font-size: 40px;
  }

  .exp-counter {
    .exp {
      width: 140px;
      font-size: 30px;
    }

    .character-exp {
      width: 140px;
    }
  }

  .campaign-details .name {
    padding-top: 4%;
    padding-bottom: 4%;
  }

  .score-title {
    font-size: 30px;
  }

  .difficulty {
    min-height: 60px;
  }
}

// 1440p Ultrawide
@media (min-width: 3440px) {
  .portrait-narrow {
    width: 12vw;
  }

  .character-exp {
    top: 6%;
  }

  .character-level {
    top: 8%;
  }

  .character-level img {
    width: 30px;
    height: 20px;
  }

  .playBtn {
    font-size: 46px;
    height: 6%;
  }

  .exp-counter {
    .exp {
      left: 85%;
    }

    .character-exp {
      left: 85%;
    }
  }

  .campaign-details .name {
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .score-title {
    font-size: 44px;
  }
}

// 4K
@media (min-width: 3840px) {
  .portrait-narrow {
    width: 17vw;
  }

  .character-name {
    font-size: 45px;
  }

  .character-exp {
    top: 5%;
  }

  .character-level {
    top: 6.5%;
    font-size: 40px;
  }

  .character-level img {
    width: 50px;
    height: 30px;
  }

  .playBtn {
    font-size: 50px;
  }

  .campaign-details {
    font-size: 62px;

    .character-level {
      min-height: 75px;
    }
  }

  .exp-counter {
    .exp {
      width: 160px;
      font-size: 32px;
      left: 85%;
    }

    .character-exp {
      width: 160px;
      left: 85%;
    }
  }

  .score-title {
    font-size: 60px;
  }

  .difficulty {
    min-height: 100px;
  }
}
