@import "../../style/ui-const.scss";

.score-text-anchor {
  max-width: 330px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}