@keyframes brighten {
  0% {
    filter: brightness(1);
    filter: contrast(1);
  }
  50% {
    filter: brightness(2);
    filter: contrast(0.6);
  }
  100% {
    filter: brightness(1);
    filter: contrast(1);
  }
}

@keyframes spinSmall {
  100% {
    transform: translate(-50%, -50%) scale(0.6) rotateZ(360deg);
  }
}

@keyframes spinLarge {
  100% {
    transform: translate(-50%, -50%) scale(0.7) rotateZ(-360deg);
  }
}

.grow {
  animation: grow 0.28s;
  animation-fill-mode: forwards;
}

.grow-slow {
  animation: grow 0.6s;
  animation-fill-mode: forwards;
}

@keyframes grow {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

.shrink {
  animation: shrink 0.6s;
  animation-fill-mode: forwards;
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.1);
  }
}

.drop-in-top {
  animation: drop-in-top 1s;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
  opacity: 1;
}

@keyframes drop-in-top {
  0% {
    top: -10%;
    opacity: 0;
  }
  100% {
    top: 10%;
    opacity: 1;
  }
}

.drop-in-center {
  animation: drop-in-center 3s;
  animation-fill-mode: forwards;
}

@keyframes drop-in-center {
  0% {
    top: 0%;
    opacity: 0;
  }
  100% {
    top: 50%;
    opacity: 1;
  }
}

.drop-in-bottom {
  animation: drop-in-bottom 1s;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
  opacity: 1;
}

@keyframes drop-in-bottom {
  0% {
    top: 110%;
    opacity: 0;
  }
  100% {
    top: 80%;
    opacity: 1;
  }
}

.disperse {
  animation: disperse 0.5s;
  animation-fill-mode: forwards;
}

@keyframes disperse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(0.5);
  }
  99% {
    opacity: 0.01;
    transform: scale(0.5);
  }
  100% {
    opacity: 0;
    transform: scale(0.01);
  }
}

.rubber-band {
  transform-origin: left;
  animation: rubber-band 0.25s;
  animation-fill-mode: forwards;
}

@keyframes rubber-band {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.25);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes rubber-band-text {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.1);
  }
  100% {
    transform: scaleX(1);
  }
}

.rubber-band-light {
  transform-origin: left;
  animation: rubber-band-light 0.25s;
  animation-fill-mode: forwards;
}

@keyframes rubber-band-light {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.flicker {
  animation: flicker 0.15s 2;
}

.flicker-disappear {
  animation: flicker 0.1s 4;
  opacity: 0;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.typing {
  animation: typing 2s steps(44) 0s 1 normal both;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 24em;
  }
}

.shake {
  animation: shake 0.5s infinite;
  animation-fill-mode: forwards;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.fade-from-black {
  animation: fade-from-black 6s;
  animation-fill-mode: forwards;
}

@keyframes fade-from-black {
  0% {
    filter: brightness(0.01);
  }
  100% {
    filter: brightness(1);
  }
}

.fade-in-slow {
  animation: fade-in-slow-pop 1.5s;
  animation-fill-mode: forwards;
}

@keyframes fade-in-slow-pop {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-slow-delay {
  animation: fade-in-slow-delay 2s;
  animation-fill-mode: forwards;
  animation-delay: 2.5s;
  opacity: 1;
}

@keyframes fade-in-slow-delay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in-slow-btn-delay {
  animation: fade-in-slow-delay 2s;
  animation-fill-mode: forwards;
  animation-delay: 6s;
  opacity: 1;
}

@keyframes fade-in-slow-delay {
  0% {
    opacity: 0;
    padding: 0;
  }

  100% {
    opacity: 1;
    padding: 15px;
  }
}

.move-stripes {
  animation: move-stripes 8s infinite;
}

@keyframes move-stripes {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0);
  }
}

.scan-line-shrink {
  animation: scan-line-shrink 1s;
  animation-fill-mode: forwards;
}

@keyframes scan-line-shrink {
  0% {
    transform: scaleX(1);
    opacity: 1;
  }
  100% {
    transform: scaleX(0.1);
    opacity: 0;
  }
}

.letter-tighten {
  animation: letter-tighten 0.75s;
  animation-fill-mode: forwards;
}

@keyframes letter-tighten {
  0% {
    letter-spacing: 0.2em;
    opacity: 1;
  }
  100% {
    letter-spacing: -0.7em;
    opacity: 0.01;
  }
}
