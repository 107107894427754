@import "../common/style/ui-const.scss";
@import "../common/style/ui-anim.scss";

.intro-bg {
  background-color: black;
  min-height: 100vh;
  text-align: center;
}

.skip-text {
  pointer-events: none;
  font-family: "Electrolize", sans-serif;
  letter-spacing: 0.4rem;
  font-size: 1.75rem;
  color: #ece8e1;
  animation: flicker 2s infinite;
  position: relative;
  top: 90vh;
}

#popupBG video {
  z-index: -1;
  filter: saturate(2) blur(5px);
}

.popup-base {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.audio-popup {
  background: black;
  transform: scale(1) !important;
}

.audio-popup-base {
  transform: translate(0%, 0%);
}

#audioPopupBg {
  position: absolute;
  opacity: 0.5;
  animation: rubber-band-text 30s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  pointer-events: none;
}

.audio-disclaimer span {
  display: block;
  position: relative;
  padding-top: 20px;
}

.popup {
  height: 50vh;
  width: 50vw;
  place-items: center;
  display: grid;
}

.popup-header {
  font-family: "Electrolize", sans-serif;
  letter-spacing: 0.4rem;
  font-size: 1.75rem;
  color: $white;
  padding-bottom: 65%;
  position: absolute;
  top: 50%;
  width: 24em;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
  pointer-events: none;
}

#popupWindow {
  position: absolute;
  opacity: 0.5;
  width: 75vw;
  animation: rubber-band-text 30s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  pointer-events: none;
}

.language-dropdown {
  width: 300px;
  margin: 100px auto;
  font-family: "Electrolize", sans-serif;
  letter-spacing: 0.1rem;

  &:hover {
    h1 {
      @extend .glow;
      filter: blur(1px) contrast(0.75);
      &:after {
        border-top-color: rgba($black, 0.7);
      }
    }
    ul li {
      @extend .glow;
      width: 300px;
      height: 50px;

      @for $i from 1 through 2 {
        &:nth-child(#{$i}) {
          transform: scaleX(1);
          opacity: 1;
        }
      }
    }
  }

  h1 {
    position: relative;
    display: block;
    width: 300px;
    height: 50px;
    line-height: 50px;
    border: 1px solid $white;
    background: rgba(#232824, 0.4);
    color: $white;
    text-shadow: 0px 4px 2px rgba($baseDark, 0.5);
    font-size: 1.6rem;
    text-align: center;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 25px;
      right: 7px;
      border-top: 10px solid $white;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 0;
    }
  }

  ul {
    list-style: none;
    position: relative;
    top: -10px;

    li {
      font-size: 1.6rem;
      text-align: center;
      line-height: 50px;
      color: $white;
      box-shadow: box-shadow(0 3px 0 5px $black inset);
      position: absolute;
      display: block;
      width: 200px;
      height: 50px;
      border: 1px solid $white;
      background: rgba(#0f1923, 0.4);
      left: 0;
      cursor: pointer;

      @for $i from 1 through 2 {
        &:nth-child(#{$i}) {
          top: 50px * ($i - 1);
          opacity: 0;
          transform: scaleX(0.1);
          @if $i % 2 == 0 {
            transform-origin: left;
          } @else {
            transform-origin: right;
          }
        }
      }

      &:hover {
        font-size: 1.8rem;
        color: rgba($black, 0.7);
        box-shadow: 0 0 25px $white, 0 1px 5px $black;

        .color-bar {
          opacity: 1;
          transform: scaleX(1);
        }
      }

      span {
        display: block;
      }

      span:hover {
        display: block;
        animation: rubber-band-text 0.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
      }

      .color-bar {
        width: 100%;
        height: 100%;
        position: absolute;
        background: $white;
        opacity: 0;
        z-index: -1;
        transform: scaleX(0.1);
        transform-origin: center;
      }
    }
  }

  * {
    transition: all 250ms ease-in-out;
  }
}

.glow {
  border-color: $white;
}

.accept-btn {
  width: 300px;
  height: auto;
  font-family: "Electrolize", sans-serif;
  font-size: 1.25rem;
  border: 1px solid $white;
  background: rgba(#232824, 0.4);
  color: $white;
  transition: all 500ms ease-in-out;
}

.disclaimer {
  font-family: "Electrolize", sans-serif;
  font-size: 1.25rem;
  color: $white;
}

.neko-disclaimer {
  pointer-events: none;
  position: absolute;
  display: grid;
  place-items: center;
  left: 63%;
  top: 60%;
  width: 25vw;

  img {
    width: 35%;
    display: block;
    transform-origin: center;
    animation: shake 0.25s infinite;
    animation-fill-mode: forwards;
  }

  span {
    font-size: 0.8rem;
    line-height: 0;
  }
}

// Pixel 2 and other similar sized Android phones
@media only screen and (max-device-width: 823px) and (max-device-height: 411px) and (orientation: landscape) {
  .popup-header {
    letter-spacing: 0.1rem;
    font-size: 0.7rem;
  }

  .language-dropdown {
    margin: 90px auto;
  }

  .neko-disclaimer {
    left: 100%;
  }

  .skip-text {
    top: 85vh;
  }
}

// iPhone X
@media only screen and (max-device-width: 812px) and (max-device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .popup-header {
    letter-spacing: 0.1rem;
    font-size: 0.7rem;
  }

  .language-dropdown {
    margin: 90px auto;
  }

  .neko-disclaimer {
    left: 100%;
  }

  .skip-text {
    top: 85vh;
  }
}

// Tablets
@media (min-width: 1024px) and (max-device-height: 1024px) {
  .popup-header {
    letter-spacing: 0.2rem;
    font-size: 1rem;
  }
}

@media (min-width: 1366px) and (max-device-height: 1024px) {
  .popup-header {
    letter-spacing: 0.3rem;
    font-size: 1.5rem;
  }
}

// 720p
@media (min-width: 1280px) and (max-device-height: 720px) {
  .popup-header {
    letter-spacing: 0.3rem;
    font-size: 1rem;
  }
}

// 1440p
@media (min-width: 2560px) {
  .popup-header {
    font-size: 2.5rem;
  }

  .popup-base {
    transform: scale(0.9);
  }
}

// 1440p Ultrawide
@media (min-width: 3440px) {
  .popup-header {
    font-size: 3.5rem;
  }

  .audio-disclaimer span {
    top: 30%;
  }

  .accept-btn {
    top: -20%;
    position: relative;
  }
}
