@import "../common/style/ui-const.scss";
@import "../common/style/ui-anim.scss";

#characterBG {
  z-index: 1;
  min-height: 100vh;
  background-color: #0e0e0e;
}

.dark-blur {
  filter: $darkBlur;
  z-index: 1;
  min-height: 100vh;
  background-color: #0e0e0e;
}

.paff-song {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  -webkit-mask: url("./../../assets/img/characters/paff/paff-song-mask.png") center center/cover;
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
}

.song-img {
  animation-name: brighten;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  pointer-events: none;
}

.static-song {
  filter: $lowBlur;
  animation-name: none;
}

.paff-song img {
  position: absolute;
  top: 15rem;
  left: 7rem;
  width: 106rem;
  height: 30rem;
}

#songStart {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  overflow: hidden;
}

.start-btn {
  z-index: 1;
  transform: translate(-50%, -50%) scale(1.4);
  filter: brightness(2) contrast(1.2) drop-shadow(2px 4px 6px black);
}

#scaleWrapper {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}

#scaleWrapper img,
#startText {
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  margin-left: -50%;
}

.start-content {
  transform: scale(1);
}

#startText {
  z-index: 2;
  transform: translate(-50%, -50%) scale(1.4);
  color: $baseDark !important;
  text-shadow: 1px 2px 2px $baseDarkShadow;
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 0.9rem;
  text-indent: 0.9rem;
}

.start-lines {
  transform: translate(-50%, -50%) scale(1.4);
  opacity: 0.4;
}

.start-cover-1 {
  transform: translate(-50%, -50%) scale(0.6);
  opacity: 0.3;
  animation: spinSmall 9s linear infinite;
}

.start-cover-2 {
  transform: translate(-50%, -50%) scale(0.7);
  opacity: 0.3;
  animation: spinLarge 9s linear infinite;
}

// Pixel 2 and other similar sized Android phones
@media only screen and (max-device-width: 823px) and (max-device-height: 411px) and (orientation: landscape) {
  .paff-song img {
    top: 6rem;
    left: 2.5rem;
    width: 41rem;
    height: 11rem;
  }

  .start-content {
    transform: scale(0.42);
  }
}

// iPhone X
@media only screen and (max-device-width: 812px) and (max-device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .paff-song img {
    top: 6rem;
    left: 2.5rem;
    width: 46rem;
    height: 11rem;
  }

  .start-content {
    transform: scale(0.42);
  }
}

// Tablets
@media (min-width: 1024px) and (max-device-height: 1024px) {
  .paff-song img {
    top: 12rem;
    left: 3.5rem;
    width: 57rem;
    height: 20rem;
  }

  .start-content {
    transform: scale(0.53);
  }
}

@media (min-width: 1366px) and (max-device-height: 1024px) {
  .paff-song img {
    top: 16rem;
    left: 5rem;
    width: 76rem;
    height: 27rem;
  }

  .start-content {
    transform: scale(0.71);
  }
}

// 720p
@media (min-width: 1280px) and (max-device-height: 720px) {
  .paff-song img {
    top: 11rem;
    left: 4.5rem;
    width: 71rem;
    height: 20rem;
  }

  .start-content {
    transform: scale(0.66);
  }
}

// 1440p
@media (min-width: 2560px) {
  .paff-song img {
    top: 23rem;
    left: 9.5rem;
    width: 141rem;
    height: 37rem;
  }

  .start-content {
    transform: scale(1.32);
  }
}

// Ultrawide 1440p
@media (min-width: 3440px) {
  .paff-song img {
    top: 21rem;
    left: 12.5rem;
    width: 190rem;
    height: 38.5rem;
  }

  .start-content {
    transform: scale(1.78);
  }
}

// 4K
@media (min-width: 3840px) {
  .paff-song img {
    top: 35rem;
    left: 14.5rem;
    width: 212rem;
    height: 54rem;
  }

  .start-content {
    transform: scale(1.98);
  }
}
