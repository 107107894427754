// Colors
$base: #f5eccb;
$baseDark: #b4aa6d;
$white: #ece8e1;
$black: rgba(#0f1923, 1);
$baseShadow: rgba(150, 150, 150, 1);
$baseDarkShadow: rgba(180, 170, 109, 0.5);
$lockBG: red;
$darkLockBG: darkred;
$lockText: orange;
$tpGreen: #68ddb5;
$scoreGold: #feec91;
$songTitle: #ebe9ce;

// Transforms
$skew: skewX(-45deg);

// Transitions
$fastTransition: 0.1s ease-out all;
$normalTransition: 0.3s ease-out all;
$slowTransition: 0.5s ease-out all;

$boxTransitionFast: box-shadow 0.2s ease-in-out;

// Filters
$lowBlur: blur(8px) brightness(0.7);
$darkBlur: blur(15px) brightness(0.7);
$perfectTint: sepia(1) hue-rotate(347deg) saturate(10) contrast(1.5);
$goodTint: sepia(1) saturate(25) hue-rotate(95deg);
$intenseTint: contrast(2);

// Classes
.active-btn {
  cursor: pointer;
  filter: contrast(2) saturate(5);

  &:hover {
    box-shadow: 0 0 25px $white, 0 1px 5px $black;
    color: rgba($black, 0.7);
    background: $white;
  }
}

.inactive-btn {
  filter: contrast(0.5);
  cursor: not-allowed;
}

.hidden {
  display: none !important;
}

.perfect-text {
  filter: $perfectTint;
}

.good-text {
  filter: $goodTint;
}

.intense-text {
  filter: $intenseTint;
}
