@import "../style/ui-const.scss";

.fade-text {
  font-family: "Electrolize", sans-serif;
  text-shadow: 2px 2px 2px $baseShadow;
  letter-spacing: 0.2em;
  font-size: 22px;
  color: white;
}

.hidden {
  visibility: hidden !important;
}
