@import "../common/style/ui-const.scss";
@import "../common/style/ui-anim.scss";

#gameBoard {
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

#scanLineAnchor {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 10px;
  transform-origin: center;
}

#scanLine {
  pointer-events: none;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: saturate(2) contrast(5);
}

#comboCounter {
  pointer-events: none;
  margin: 0;
  position: absolute;
  top: 4vh;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  transform-origin: left;
}

#comboCounter span {
  display: block;
  font-family: "Electrolize", sans-serif;
  text-shadow: 0 0 8px $baseShadow;
  letter-spacing: 0.2em;
  color: $white;
  line-height: 1;
}

#comboNum {
  font-size: 2.5rem;
  transform: translate(5%, 0%);
  min-height: 4vh;
}

#comboText {
  font-size: 0.9rem;
}

#scoreCounter {
  pointer-events: none;
  margin: 0;
  position: absolute;
  top: 4vh;
  left: 95%;
  transform: translate(-50%, -50%);
  text-align: center;
  transform-origin: left;
}

#scoreCounter span {
  display: block;
  font-family: "Electrolize", sans-serif;
  font-size: 2rem;
  text-shadow: 0 0 8px $baseShadow;
  letter-spacing: 0.2em;
  color: $white;
  line-height: 1;
}

#resultsContainer {
  position: absolute;
  height: 100vh;
  width: 100vw;
  text-align: center;

  .song-info {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Electrolize", sans-serif;
    font-size: 3rem;
    text-shadow: 0 0 8px #969696;
    letter-spacing: 0.1em;
    color: $songTitle;
    line-height: 1;
    opacity: 0;
  }

  .result-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(1);
  }

  .start-btn {
    position: absolute;
    z-index: 1;
    transform: translate(-50%, -50%) scale(1.4);
    filter: drop-shadow(2px 4px 6px black);
  }

  .start-lines {
    position: absolute;
    transform: translate(-50%, -50%) scale(1.4);
    opacity: 0.5;
  }

  .score-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Electrolize", sans-serif;
    text-shadow: 0 0 8px #969696;
    letter-spacing: 0.1em;
    color: $scoreGold;
    line-height: 1;
    width: 100vw;
    opacity: 0;

    .score-details {
      display: inline-block;
      text-align: center;
      width: 50%;
      /*NOTE: Set width to 30% once TP comes back */
      // width: 30%;

      span {
        display: block;
      }

      span:nth-of-type(1) {
        font-size: 1.5rem;
      }

      span:nth-of-type(2) {
        font-size: 4rem;
        padding-top: 10px;
      }
    }

    .tp-info {
      transform: translate(0, 200%);
      padding-left: 20px;
      color: $tpGreen;

      span:nth-of-type(1) {
        padding-right: 10px;
      }

      span:nth-of-type(2) {
        font-size: 2rem;
      }
    }
  }

  .note-info {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Electrolize", sans-serif;
    text-shadow: 0 0 8px #969696;
    letter-spacing: 0.1em;
    color: $base;
    line-height: 1;
    width: 100vw;
    filter: brightness(0.75);
    opacity: 0;

    .note-type {
      display: inline-block;
      text-align: center;
      width: 15%;

      span {
        display: block;
      }

      span:nth-of-type(1) {
        font-size: 1.5rem;
      }

      span:nth-of-type(2) {
        font-size: 2.5rem;
        padding-top: 10px;
      }
    }
  }

  .return-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Electrolize", sans-serif;
    font-size: 2rem;
    text-shadow: 0 0 8px $baseDark;
    letter-spacing: 0.1em;
    color: $scoreGold;
    line-height: 1;
    width: auto;
    border: 1px solid $baseDark;
    transition: all 500ms ease-in-out;
    opacity: 0;

    &:hover {
      background: $base;
      color: black;
      cursor: pointer;
    }
  }
}

// Pixel 2 and other similar sized Android phones
@media only screen and (max-device-width: 823px) and (max-device-height: 411px) and (orientation: landscape) {
  #resultsContainer {
    .result-bg {
      transform: scale(0.42);
    }

    .return-btn {
      font-size: 1rem;
    }

    .score-details span {
      font-size: 1rem !important;
    }

    .tp-info span {
      font-size: 0.75rem !important;
    }

    .note-info {
      .note-type span {
        padding-top: 50px;
        font-size: 1rem !important;
      }
    }
  }

  #scoreCounter {
    left: 90%;
  }

  #comboText {
    font-size: 0.1rem;
  }
}

// iPhone X
@media only screen and (max-device-width: 812px) and (max-device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  #resultsContainer {
    .result-bg {
      transform: scale(0.42);
    }

    .return-btn {
      font-size: 1rem;
    }

    .score-details span {
      font-size: 1rem !important;
    }

    .tp-info span {
      font-size: 0.75rem !important;
    }

    .note-info {
      .note-type span {
        padding-top: 50px;
        font-size: 1rem !important;
      }
    }
  }

  #scoreCounter {
    left: 90%;
  }

  #comboText {
    font-size: 0.1rem;
  }
}

// Tablets
@media (min-width: 1024px) and (max-device-height: 1024px) {
  #resultsContainer {
    .result-bg {
      transform: scale(0.53);
    }

    .return-btn {
      font-size: 1rem;
    }

    .score-details span {
      font-size: 1rem !important;
    }

    .tp-info span {
      font-size: 1rem !important;
    }
  }

  #scoreCounter {
    left: 90%;
  }
}

@media (min-width: 1366px) and (max-device-height: 1024px) {
  #resultsContainer {
    .result-bg {
      transform: scale(0.71);
    }

    .return-btn {
      font-size: 1rem;
    }

    .score-details span {
      font-size: 1rem !important;
    }

    .tp-info span {
      font-size: 1rem !important;
    }
  }

  #scoreCounter {
    left: 90%;
  }
}

// 720p
@media (min-width: 1280px) and (max-device-height: 720px) {
  #resultsContainer {
    .result-bg {
      transform: scale(0.66);
    }

    .return-btn {
      font-size: 1rem;
    }

    .score-details span {
      font-size: 1rem !important;
    }

    .tp-info span {
      font-size: 1rem !important;
    }
  }

  #scoreCounter {
    left: 90%;
  }
}

// 1440p
@media (min-width: 2560px) {
  #resultsContainer .result-bg {
    transform: scale(1.32);
  }
}

// Ultrawide 1440p
@media (min-width: 3440px) {
  #resultsContainer .result-bg {
    transform: scale(1.78);
  }
}

// 4K
@media (min-width: 3840px) {
  #resultsContainer .result-bg {
    transform: scale(1.98);
  }
}
